import React from 'react'
import Calendar from 'react-calendar'
import 'react-calendar/dist/Calendar.css';
import './OpeningDays.css'
import moment from 'moment'
import {Zoom} from 'react-awesome-reveal';

const OpeningDays = () => {

  const datesOpenAndAvailability = {
    '02-08-2024': false,
    '23-08-2024': false,
    '24-08-2024': false
    
  }

  const datesCateringEvent = [
    '03-08-2024',
    '09-08-2024',
    '10-08-2024',
    '16-08-2024',
    '17-08-2024',
    '30-08-2024',
    '31-08-2024',
  ]

    const dateMinus1 = new Date();
    dateMinus1.setDate(dateMinus1.getDate() - 1);

  return (
    <Zoom triggerOnce={true}>
    <div className='opening_times'>
      <div className='key_container'>
        <div className='key_item'>
          <div className='color_key'></div>
          <span className='key_txt'>Open</span>
        </div>
        <div className='key_item'>
          <div className='color_key one' style={{opacity: '0.4'}}></div>
          <span className='key_txt'>Fully Booked</span>
        </div>
        <div className="break_keys"></div>
        <div className='key_item'>
          <div className='color_key' style={{backgroundColor:'#5b3c1e'}}></div>
          <span className='key_txt'>Catering Event</span>
        </div>
        <div className='key_item'>
          <div className='color_key' style={{backgroundColor:'white', border: '1px solid black'}}></div>
          <span className='key_txt'>Closed</span>
        </div>
    </div>
    <div className="break"></div>
      <Calendar
      className="react_calendar"
      minDate={new Date(Date.now())}
      maxDate={new Date(2024, 7, 31)}
      defaultView={"month"}
      prevLabel="‹"
      prev2Label={null}
      nextLabel="›"
      next2Label={null}
      selectRange={false}
      maxDetail={"month"}
      showNeighboringMonth={false}
      tileClassName={({ date, view }) => {
        if(datesOpenAndAvailability[moment(date).format("DD-MM-YYYY")] === true && date >= dateMinus1 ){
          return  'full'
        }

        if((moment(date).format("DD-MM-YYYY")) in datesOpenAndAvailability && date >= dateMinus1){
          return  'highlight'
        }

        if(datesCateringEvent.includes((moment(date).format("DD-MM-YYYY"))) && date >= dateMinus1){
          return  'highlightCatering'
        }

        }}
      />
    <div className="break"></div>
    <Zoom className='zoom-container' triggerOnce={true}>
      <span className='limited_txt'>As we are currently running as a pop-up and working around our outside catering our opening dates are limited.</span>
    </Zoom>
    </div>
    </Zoom>
  )
}

export default OpeningDays