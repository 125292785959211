import React from 'react';
import './Restaurant.css';
import SubSection from '../SubSection';
import ImageHeader from '../ImageHeader';
import Map from '../Map';
import Carousel from "../Carousel";
import Menus from '../Menus'
import Reservations from '../Reservations';

function Restaurant() {

  const reviews = [
    <p className='review_txt'>"You don’t usually get food or service of this calibre outside a big city. Dunoon is so fortunate to have this wonderful restaurant back again"</p>,
    <p className='review_txt'>"We had the most amazing meal and evening in chatters last night, thank you all. Both the beef and the venison were delicious and all the wee extras make it such a special experience. The dessert trolley is what dreams are made of.
    Love being seated in the cosy living room area for drinks and then enjoying our meal in the romantic dining area. Cannot wait to return. Simply special Chatters!"</p>,
    <p className='review_txt'>"If you're looking to share some special moments with family and friends, Chatters will make them all the more memorable."</p>,
    <p className='review_txt'>"The warm inviting atmosphere is unique to Chatters. We were welcomed like VIPs and made to feel so special and appreciated all evening."</p>,
    <p className='review_txt'>"Chatters is back with friendly and efficient staff, fabulous food and an evening that is a special event every time you go."</p>,
    <p className='review_txt'>"The smoothness of the intricacies of service, the staff floating like ballerinas as glasses were refilled and plates served was like being in one of the world’s capital cities"</p>,
  ];


  return (
    <>
      <ImageHeader 
        headerText='RESTAURANT' 
        imgPath={require('../../images/chatters-dining.jpg')}
        imgPathNarrow={require('../../images/chatters-dining-narrow.jpg')} 
        isHero={true}
      />
      <div className='restaurant_body'>
        <SubSection/>
        <Carousel
          reviews={reviews}
          title="REVIEWS"
        />
        {/* <div className='page_img_container' style={{backgroundImage:`url(${require('../../images/chatters-sitting-garden.jpg')})`}}></div> */}
        <Menus/>
        {/* <div className='page_img_container' style={{backgroundImage:`url(${require('../../images/chatters-garden-sitting-dark.jpg')})`}}></div> */}
        <Reservations/>
        <div className="break" style={{marginTop:'10px', marginBottom:'30px'}}></div>
        <Map width={'100%'} height={'60vh'} src={'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1857.0443603408403!2d-4.928728324619774!3d55.95196582041807!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4889a495ae9e5587%3A0x8fb6d4606e4ea952!2sChatters%20Brasserie!5e0!3m2!1sen!2suk!4v1672140163512!5m2!1sen!2suk'}/>
      </div>
    </>
  );
}

export default Restaurant;